import React from "react"
import LogoHeader from "../general/LogoHeader"
import {Input, Button} from "@nextui-org/react"
import {useNavigate} from "react-router-dom"
import {Tabs, Tab, Card, CardBody} from "@nextui-org/react"
import {Icons} from "../icons/Icons"
import {AnimatePresence, motion} from "framer-motion"

import supabase from "../../supabaseConfig"
import {AuthApiError} from "@supabase/supabase-js"

function AnimatedCheckIcon({
  initial = true,
  isVisible = false,
  color = "white",
}: {
  initial?: boolean
  isVisible?: boolean
  color?: string
}) {
  return (
    <AnimatePresence initial={initial}>
      {isVisible && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={color}
          className="CheckIcon"
        >
          <motion.path
            initial={{pathLength: 0}}
            animate={{pathLength: 1}}
            exit={{pathLength: 0}}
            transition={{
              type: "tween",
              duration: 0.3,
              ease: isVisible ? "easeOut" : "easeIn",
            }}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      )}
    </AnimatePresence>
  )
}

export default function LoginContainer() {
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  const [selected, setSelected] = React.useState("login")
  const [isCheckIconVisible, setIsCheckIconVisible] = React.useState(false)

  function handleCheckIconToggle() {
    setIsCheckIconVisible((isCheckIconVisible) => !isCheckIconVisible)
  }

  const handleLogin = async (type: "password" | "magic-link") => {
    if (type === "password") {
      if (!email || !password) {
        return
      }
      // TODO: logic for user name password login
    } else if (type === "magic-link") {
      if (!email) {
        return
      }
      window.localStorage.setItem("emailForSignIn", email)
      setLoading(true)

      const baseURL = window.location.origin
      try {
        const {data, error} = await supabase.auth.signInWithOtp({
          email: email,
          options: {
            // set this to false if you do not want the user to be automatically signed up
            shouldCreateUser: false,
            emailRedirectTo: `${baseURL}`,
          },
        })
        if (error) throw error
        console.log("data", data)
        setEmailSent(true)
        setIsCheckIconVisible(true)
        setTimeout(() => setIsCheckIconVisible(false), 3000)
      } catch (error) {
        if (error instanceof AuthApiError) {
          console.log("error", error.code)
          if (error.code === "over_email_send_rate_limit") {
            setError(
              "Too many requests in a short period of time. Please try again later."
            )
          } else {
            setError(
              "Your email has not been registered with Quartz. Please contact support@obsidianlabs.co to resolve this issue."
            )
          }
          console.log("error on signup")
        } else {
          throw error // rethrow the error if it's not an AuthApiError
        }
      }
    }
  }

  return (
    <div className=" flex h-full w-full max-w-[400px] flex-col items-center  justify-center">
      <section className="rounded-xl border border-black/5 bg-white px-4 py-4 ">
        <div className="flex items-center justify-start gap-2 border-b border-gray-200 pb-4">
          <LogoHeader />
          {/* <p>x American Nitrile</p> */}
        </div>

        <div className="mb-2 ml-4 mr-4 mt-4 flex flex-col gap-1">
          <p className="text-3xl font-bold">Login</p>
          <p className="mt-1 text-sm leading-none text-onyx-black/60">
            To login, use your email and password given by the Onyx Team or send
            a magic link to your account email.
          </p>
        </div>
      </section>

      <Card className="mt-4 min-h-[400px] w-full rounded-xl bg-onyx-white p-4 transition-all">
        <CardBody className="overflow-hidden">
          {emailSent ? (
            <div className="flex h-full w-full flex-col items-start justify-center gap-2 p-2 transition-all">
              {/* check animation */}
              {/* <Icons iconName="check" scalar={0.95} strokeColor="green" /> */}
              <div className="mb-4 h-12 w-12 animate-appearance-in  rounded-full bg-green-500 px-2 pb-2 pt-[0.65rem] duration-500">
                {/* <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial={{pathLength: 0}}
                    animate={{pathLength: 1}}
                    d="M4.5 12.75l6 6 9-13.5"
                  /> */}
                <AnimatedCheckIcon isVisible={isCheckIconVisible} />
              </div>
              <p className="text-lg font-bold leading-6">
                Email was sent to: {email}
              </p>
              <p className="text-xs">
                Please check your email and follow the link to login. If you did
                not receive an email or were not redirected, please try another
                email or contact{" "}
                <a
                  href="mailto:support@onyxindustrials.com"
                  className="text-onyx-black underline"
                >
                  support@onyxindustrials.com
                </a>
                .
              </p>
            </div>
          ) : (
            <Tabs
              fullWidth
              // size="md"
              aria-label="Tabs form"
              selectedKey={selected}
              onSelectionChange={(key) => setSelected(key as string)}
            >
              {/* <Tab
                key="password"
                title={
                  <div className="flex items-center space-x-1">
                    <div
                    // className={`rounded-md p-[2.5px] ${
                    //   selected === "password"
                    //     ? "bg-onyx-black/25"
                    //     : "bg-zinc-300"
                    // }`}
                    >
                      <Icons
                        iconName="password"
                        scalar={0.95}
                        strokeColor={selected === "password" ? "black" : "grey"}
                      />
                    </div>
                    <span>Email/Password</span>
                  </div>
                }
                className="h-full"
              >
                <form className="flex h-full flex-col gap-4 ">
                  <Input
                    isRequired
                    label="Email"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    isRequired
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error && (
                    <p className="ml-4 mr-4 mt-2 text-xs text-red-500">
                      {error}
                    </p>
                  )}
                  <div className="flex h-full justify-between gap-2">
                    <Button
                      fullWidth
                      color="secondary"
                      variant="ghost"
                      className="self-end"
                      onClick={() => handleLogin("password")}
                    >
                      Login
                    </Button>
                  </div>
                </form>
              </Tab> */}
              <Tab
                key="magic-link"
                title={
                  <div className="flex items-center space-x-1">
                    <div
                    // className={`rounded-md p-[2.5px] ${
                    //   selected === "password"
                    //     ? "bg-onyx-black/25"
                    //     : "bg-zinc-300"
                    // }`}
                    >
                      <Icons
                        iconName="magicLink"
                        scalar={0.95}
                        strokeColor={
                          selected === "magic-link" ? "black" : "grey"
                        }
                      />
                    </div>
                    <span>Email Magic Link</span>
                  </div>
                }
                className="h-full"
              >
                <form
                  className="flex h-full flex-col gap-4"
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleLogin("magic-link")
                  }}
                >
                  <Input
                    isRequired
                    label="Email"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onSubmit={() => handleLogin("magic-link")}
                  />
                  {error && (
                    <p className="ml-4 mr-4 mt-2 text-xs text-red-500">
                      {error}
                    </p>
                  )}
                  <div className="flex h-full justify-end gap-2">
                    <Button
                      fullWidth
                      color="secondary"
                      className="self-end"
                      variant="ghost"
                      // isLoading={loading}
                      onClick={() => {
                        handleLogin("magic-link")
                      }}
                    >
                      Send Magic Link
                    </Button>
                  </div>
                </form>
              </Tab>
            </Tabs>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

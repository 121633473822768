import React from "react"
import {twMerge} from "tailwind-merge"
import {IconLoader} from "@tabler/icons-react"

export function DataLoader() {
  return (
    <div className="flex h-full w-full flex-row items-center justify-center gap-4 ">
      <IconLoader className="animate-spin" />
      <p className="text-sm text-onyx-black/90">Loading your data...</p>
    </div>
  )
}

import {useQuery} from "@apollo/client"
import {
  GetMyUserDashboardDocument,
  GetMyUserDashboardQuery,
} from "@/gql/graphql"
import {DashboardResponse} from "./shared"
import {sanitizeDashboardResponse} from "./shared"

function sanitizeResponse(data: GetMyUserDashboardQuery): DashboardResponse {
  return sanitizeDashboardResponse(
    data?.user_dashboardCollection?.edges[0]?.node
  )
}

export function useGetMyUserDashboard(userId: string | null) {
  if (!userId) {
    console.debug("👻 [Dashboard] No user ID provided, query skipped")
  }

  const result = useQuery<GetMyUserDashboardQuery>(GetMyUserDashboardDocument, {
    variables: {
      auth_user_id: userId,
    },
    skip: !userId,
    onError: (error) => {
      console.info("💥 [Dashboard] Query failed:", error.message)
    },
    onCompleted: (data) => {
      console.debug("📊 [Dashboard] Data loaded:", {
        hasData: !!data,
        userFound: !!data?.user_dashboardCollection?.edges[0],
      })
    },
  })

  const sanitizedData = result.data ? sanitizeResponse(result.data) : undefined
  console.debug("🧹 [Dashboard] Sanitized data:", {
    isAdmin: sanitizedData?.isAdmin,
    hasIntegrations: !!sanitizedData?.integrations,
    integrationCount: sanitizedData?.integrations
      ? Object.keys(sanitizedData.integrations).length
      : 0,
  })

  return {
    ...result,
    data: sanitizedData,
  }
}

import {IconLockAccess, IconArrowUpRight} from "@tabler/icons-react"
import {Link} from "@nextui-org/react"

type AuthErrorType =
  | "login_timeout"
  | "invite_expired"
  | "not_admin"
  | "not_logged_in"

const AuthError = ({
  type,
  pageContainer = false,
}: {
  type: AuthErrorType
  pageContainer?: boolean
}) => {
  const wrapWithPage = (content: JSX.Element) =>
    pageContainer ? (
      <div className="flex h-screen flex-col items-center justify-center">
        {content}
      </div>
    ) : (
      content
    )

  switch (type) {
    case "login_timeout":
      return wrapWithPage(
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <IconLockAccess size={48} />
            <div className="flex flex-col">
              <p className="text-xl font-medium leading-none">Link Expired</p>
              <p className="text-md text-neutral-400">Login Timeout Error</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm">
              Return to the{" "}
              <Link
                href="/login"
                className="text-sm text-primary"
                anchorIcon={<IconArrowUpRight size={16} />}
                showAnchorIcon
              >
                login page
              </Link>{" "}
              to request a new link. You can close this page.
            </p>
            <p className="text-xs">
              If you continue to experience issues, please contact
              support@obsidianlabs.co
            </p>
          </div>
        </div>
      )
    case "invite_expired":
      return wrapWithPage(
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <IconLockAccess size={48} />
            <div className="flex flex-col">
              <p className="text-xl font-medium leading-none">
                Invite Link Expired
              </p>
              <p className="text-md text-neutral-400">Login Timeout Error</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm">
              Please request your system administrator to send a new link invite
            </p>
          </div>
        </div>
      )

    case "not_admin":
      return wrapWithPage(
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <IconLockAccess size={48} />
            <div className="flex flex-col">
              <p className="text-xl font-medium leading-none">
                No Admin Access
              </p>
              <p className="text-md text-neutral-400">
                Insufficient Permissions
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm">
              You do not have admin access to this organization. Please contact
              your system administrator to access this page.
            </p>
          </div>
        </div>
      )
    case "not_logged_in":
      return wrapWithPage(
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <IconLockAccess size={48} />
            <div className="flex flex-col">
              <p className="text-xl font-medium leading-none">Not Logged In</p>
              <p className="text-md text-neutral-400">
                You cannot access this page without logging in.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm">
              Return to the{" "}
              <Link
                href="/login"
                className="text-sm text-primary"
                anchorIcon={<IconArrowUpRight size={16} />}
                showAnchorIcon
              >
                login page
              </Link>{" "}
              to login. You can close this page.
            </p>
            <p className="text-xs">
              If you continue to experience issues, please contact
              support@obsidianlabs.co
            </p>
          </div>
        </div>
      )
  }
}

export default AuthError

import React from "react"
import {IconLock} from "@tabler/icons-react"

const ContactInfo = ({participant}: {participant: any}) => {
  const [clicked, setClicked] = React.useState<boolean>(false)
  return (
    <div
      className="my-[2px] flex w-fit min-w-fit items-center justify-center gap-1 rounded-md border border-black/5 bg-white px-1 py-1 "
      onClick={() => {
        setClicked(true)
      }}
    >
      <IconLock className="h-4 w-4 text-neutral-300" />
      {/* {clicked ? (
        <IconLoader className="h-4 w-4 animate-spin" />
      ) : (
        <IconCloudDownload className="h-4 w-4" />
      )} */}
      <p className="cursor-pointer text-sm font-medium text-neutral-300">
        Pull Contact Information
      </p>
    </div>
  )
}

export default ContactInfo

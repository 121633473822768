import {twMerge} from "tailwind-merge"
import {IconX} from "@tabler/icons-react"
import {LockedFeatureIndicator} from "../general/ui/LockedFeatureIndicator"

import ContactInfo from "./ContactInfo"

function convertDate(date: any | undefined) {
  // takes fb timestamp string and returns date
  if (date) {
    return new Date(date).toLocaleDateString()
  }
  return "N/A"
}

function convertTimestamp(timestamp: any | undefined) {
  // takes fb timestamp string and returns 24 hour time
  // timestamp = new Date(timestamp)
  if (timestamp) {
    return new Date(timestamp).toISOString()
  }
  return "N/A"
}

function getDuration(start: string, end: string) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const duration = endDate.getTime() - startDate.getTime()
  const hours = Math.floor(duration / (1000 * 60 * 60))
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
  return `${hours}h ${minutes}m`
}

const EmailView = ({
  selectedThread,
  setSelectedThread,
  selectedThreadGP,
}: {
  selectedThread: any
  setSelectedThread: any
  selectedThreadGP: any
}) => {
  return (
    <div
      className={twMerge(
        "h-full max-w-[70%] overflow-scroll rounded-md border border-black/5 transition-all duration-150 scrollbar-hide",
        selectedThread ? "w-[70%] opacity-100" : "w-[0%] opacity-0"
      )}
    >
      <div className="sticky top-0 flex flex-col gap-2 border-b border-black/5 bg-white px-2 py-1 pb-3 pl-4">
        <IconX
          color="red"
          className="mt-1 h-3 w-3 self-end rounded-full bg-danger-100"
          onClick={() => setSelectedThread(null)}
        />
        {selectedThread?.title ? (
          <p className="text-xl font-medium">
            {selectedThread?.title || "Ongoing Conversation"}
          </p>
        ) : (
          <div className="flex flex-row items-center gap-1">
            <LockedFeatureIndicator />
            <p className="text-xl font-medium text-neutral-300">
              AI-Generated Conversation Title{" "}
            </p>
          </div>
        )}
        {selectedThread?.description ? (
          <p className="font-regular text-sm">
            {selectedThread?.description ||
              "This is an ongoing conversation that Quartz will process once it has deemed that the conversation thread has ended"}
          </p>
        ) : (
          <div className="flex flex-row items-center gap-1">
            <p className="font-regular text-sm text-neutral-400">
              This will be an AI-generated description to help an admin user
              better understand the high level context of this message thread.
            </p>
          </div>
        )}

        <div className="flex flex-row items-start gap-2">
          <p className="w-fit rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium ">
            Dates:
          </p>

          <div className="ml-2 mt-1 flex flex-col gap-1 text-xs">
            <div className="flex flex-row gap-2">
              <p className="font-medium">Created At:</p>
              <p className="font-regular">
                {convertDate(selectedThread?.created_at)}
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <p className="font-medium">Start:</p>
              <p className="font-regular">
                {convertTimestamp(selectedThread?.created_at)}
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <p className="font-medium">End:</p>
              <p className="font-regular">
                {convertTimestamp(selectedThread?.last_message_timestamp)}
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <p className="font-medium">Duration:</p>
              <p className="font-regular">
                {getDuration(
                  selectedThread?.created_at,
                  selectedThread?.last_message_timestamp
                )}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="mt-4 w-fit rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium ">
            Participants:
          </p>
        </div>

        <div>
          {selectedThreadGP.map((participant) => {
            let clicked = false
            // const participant = node.node.profiles
            // console.log(participant)
            // return <div>{participant.phone_number}</div>
            if (!participant) return null
            console.log("participant", participant)
            return (
              <div className="flex w-full flex-row items-center gap-2">
                {/* <div className="ml-[0.375rem] h-1 w-1 rounded-full bg-primary-500"></div> */}
                {participant.phone_number !== "NONE" || participant.name ? (
                  <p className="w-fit min-w-fit font-medium">
                    {participant.name}
                  </p>
                ) : (
                  <ContactInfo participant={participant} />
                )}
                {!participant.name && <ContactInfo participant={participant} />}

                <div className="flex flex-row gap-1 overflow-x-scroll scrollbar-hide">
                  <p className="font-regular text-xs">
                    {participant.phone_number !== "NONE"
                      ? participant.phone_number
                      : participant.name}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="px-4 py-2">
        <p className=" w-fit rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium ">
          Messages:
        </p>
      </div>
      <div className="flex h-full w-full flex-col gap-2  bg-onyx-slate/15 bg-grid-small-onyx-black/15">
        {" "}
        {selectedThread?.messages.map((node, idx) => {
          // const sameSender =
          //   message.sender_uid ===
          //   selectedThread?.message_docs[idx - 1]?.sender_uid
          const sameSender = false
          const message = node
          console.log("message", message)
          return (
            <div
              className={twMerge(
                "flex flex-col gap-1",
                sameSender ? "px-2" : "px-2 pt-2"
              )}
              key={idx}
            >
              {!sameSender && (
                <div className="flex flex-row items-center gap-2">
                  <div className="flex w-fit flex-row items-center gap-1 rounded-md border border-black/5 bg-onyx-white px-2 py-1 text-xs">
                    <p className="text-xs font-medium ">
                      {/* fix */}
                      {message?.profiles?.name ||
                        message?.profiles?.phone_number ||
                        message?.extraction_message?.contact_name}
                    </p>
                    <p className="font-regular text-xs text-neutral-400">
                      {new Date(message.created_at).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        }
                      )}
                    </p>
                  </div>
                </div>
              )}
              {message.message && (
                <div className="ml-1 flex flex-row items-center gap-2 rounded-sm bg-onyx-slate p-1">
                  <p className="text-sm font-[350]">
                    {message.message || "Loading..."}
                  </p>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EmailView

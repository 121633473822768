import supabase from "@/supabaseConfig"

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const confirmInvitation = async (): Promise<void> => {
  const session = await supabase.auth.getSession()
  const token = session.data.session?.access_token
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  if (!token) {
    throw new Error("No token found in session.")
  }
  console.log("confirming invitation")

  const response = await fetch(
    `${supabaseUrl}/functions/v1/confirm-invitation`,
    {
      method: "POST",
      headers: {
        apiKey: apiKey,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }
}

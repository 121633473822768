import supabase from "@/supabaseConfig"

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const inviteUser = async ({
  userName,
  userEmail,
  session,
}: {
  userName: string
  userEmail: string
  session: any
}): Promise<void> => {
  const token = session
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  if (!token) {
    throw new Error("No token found in session.")
  }

  const response = await fetch(`${supabaseUrl}/functions/v1/invite-user`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    },
    body: JSON.stringify({userName, userEmail}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }
}

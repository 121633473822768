import {Session} from "@supabase/supabase-js"
import supabase from "../supabaseConfig"

export const checkAdmin = async (session: Session) => {
  if (!session?.user?.id) {
    // supabase.auth.signOut()
    // navigate("/")
    return false
  }
  const {data: userData, error: userError} = await supabase
    .from("org_users")
    .select("role")
    .eq("auth_user_id", session?.user?.id)
    .single()
  console.log("USER IS ADMIN", userData?.role === "ADMIN")
  return userData?.role === "ADMIN"
}

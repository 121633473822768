import {useQuery} from "@apollo/client"
import {
  GetOrgUsersDashboardDocument,
  GetOrgUsersDashboardQuery,
} from "@/gql/graphql"
import {DashboardResponse} from "./shared"
import {sanitizeDashboardResponse} from "./shared"

function sanitizeResponse(
  data: GetOrgUsersDashboardQuery
): DashboardResponse[] {
  return data?.user_dashboardCollection?.edges.map((edge) =>
    sanitizeDashboardResponse(edge.node)
  )
}

export function useGetOrgUsersDashboard() {
  const result = useQuery<GetOrgUsersDashboardQuery>(
    GetOrgUsersDashboardDocument,
    {
      onError: (error) => {
        console.info("💥 [OrgDashboard] Query failed:", error.message)
      },
      onCompleted: (data) => {
        console.debug("🏢 [OrgDashboard] Data loaded:", {
          hasData: !!data,
          userCount: data?.user_dashboardCollection?.edges?.length ?? 0,
        })
      },
    }
  )

  const sanitizedData = result.data ? sanitizeResponse(result.data) : undefined
  console.debug("🧹 [OrgDashboard] Sanitized data:", {
    userCount: sanitizedData?.length ?? 0,
    hasData: !!sanitizedData,
  })

  return {
    ...result,
    data: sanitizedData,
  }
}

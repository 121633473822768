import {useEffect, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import supabase from "../../../supabaseConfig"
import AuthError from "@/components/auth/AuthError"
import {confirmInvitation} from "@/utils/api/functions/confirm-invitation"

export default function ConfirmUserCallback() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState(false)

  useEffect(() => {
    const getAuthParams = () => {
      const urlParams = new URLSearchParams(searchParams.toString())
      const hashParams = new URLSearchParams(
        window.location.hash.replace("#", "")
      )

      const params = {
        token_hash: hashParams.get("token_hash") || urlParams.get("token_hash"),
        type: hashParams.get("type") || urlParams.get("type"),
        access_token: hashParams.get("access_token"),
        refresh_token: hashParams.get("refresh_token"),
      }

      console.debug("🔍 Auth params retrieved:", {
        hasTokenHash: !!params.token_hash,
        hasType: !!params.type,
        hasAccessToken: !!params.access_token,
        hasRefreshToken: !!params.refresh_token,
      })
      return params
    }

    const handleExistingSession = async () => {
      console.info("🔍 Checking for existing session...")
      const {data, error} = await supabase.auth.getSession()
      console.debug("📋 Existing session check result:", {
        hasSession: !!data.session,
        error: error,
      })
      if (data.session) {
        console.info("✅ Found existing session, redirecting...")
        navigate("/auth/onboarding/configure_integrations")
        return true
      }
      return false
    }

    const setSessionWithTimeout = async (
      access_token: string,
      refresh_token: string
    ) => {
      console.info("🔄 Attempting to set session with timeout...")
      const sessionPromise = supabase.auth.setSession({
        access_token,
        refresh_token,
      })
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Session setup timed out")), 5000)
      )
      return Promise.race([sessionPromise, timeoutPromise])
    }

    const fetchSession = async () => {
      try {
        console.info("🚀 Starting auth flow...")
        const {token_hash, type, access_token, refresh_token} = getAuthParams()

        if (access_token && refresh_token) {
          console.info("🔑 Found access and refresh tokens, setting session...")
          await setSessionWithTimeout(access_token, refresh_token)
          console.info("✅ Session set successfully, redirecting...")
          navigate("/auth/onboarding/configure_integrations")
          return
        }

        console.info("🔄 No tokens found, checking for existing session...")
        const hasExistingSession = await handleExistingSession()
        if (hasExistingSession) return

        console.info("❌ All auth methods failed, showing error...")
        setError(true)
      } catch (error) {
        console.error("❌ Auth flow failed:", error)
        setError(true)
      }
    }

    fetchSession()

    confirmInvitation()
  }, [navigate, searchParams])

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      {error ? (
        <AuthError type="invite_expired" />
      ) : (
        <div>Confirming your login...</div>
      )}
    </div>
  )
}

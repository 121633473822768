import supabase from "@/supabaseConfig"

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export type Participant = {
  name: string
  phone_number: string
}
// Define a type for the thread to improve type safety
export type Thread = {
  thread_uid: string
  title: string
  description: string
  last_message_timestamp: string
  created_at: string
  participants: Participant[]
}

export const getNumberOfUsers = async (
  session: any = null
): Promise<number> => {
  const token = session
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  const query = `
    query getOrgUsers{
  org_usersCollection {
    edges {
      node {
        profile_id
      }
    }
  }
}
  `

  const response = await fetch(`${supabaseUrl}/graphql/v1`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.access_token}`,
    },
    body: JSON.stringify({query}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }

  const data = await response.json()
  return data.data.org_usersCollection.edges.length
}

export type OrgUser = {
  profile_id: string
  profiles: Profile
}

export type Profile = {
  created_at: string
  phone_number: string
  name: string
  email: string
  title: string
  company: string
  is_active: boolean
  auth_user_id: string
  org_id: string
}

export const getOrgUsers = async (session: any = null): Promise<OrgUser[]> => {
  const token = session
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  const query = `
    query getOrgUsers{
  org_usersCollection {
    edges {
      node {
        profile_id
        profiles {
          created_at
          phone_number
          name
          email
          title
          company
          is_active
          auth_user_id
          org_id
        }
      }
    }
  }
}
  `

  const response = await fetch(`${supabaseUrl}/graphql/v1`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    },
    body: JSON.stringify({query}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }

  const data = await response.json()
  return data.data.org_usersCollection.edges.map((edge) => edge.node)
}

// update profile

export const updateProfile = async (phone_number: string): Promise<any> => {
  const token = await supabase.auth.getSession()
  const user_ID = token.data.session.user.user_metadata.auth_user_id
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  const query = `
  mutation{
  updateprofilesCollection(
    set:{phone_number:"${phone_number}"}
    filter:{auth_user_id:{eq:"${user_ID}"}}
  )
  {
    affectedCount
  }
}
  `

  const response = await fetch(`${supabaseUrl}/graphql/v1`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.session?.access_token}`,
    },
    body: JSON.stringify({query}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }

  const data = await response.json()
  return data.data.threadsCollection.edges.map((edge) => edge.node)
}

import {useEffect, useState} from "react"
import {useUser} from "@/contexts/UserContextProvider"
import AuthError from "@/components/auth/AuthError"
import {useNavigate} from "react-router-dom"

import {sanatizeResponse} from "@/utils/graphQL/queries/checkRoleAndIntegrationStatus"
import {useGetMyUserDashboard} from "../graphQL/queries/userDashboard/useGetMyUserDashboard"

const AuthenticatedRouteProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {session} = useUser()
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState<number | null>(null)

  const {data, loading, error, refetch} = useGetMyUserDashboard(
    session?.user?.id
  )

  useEffect(() => {
    console.debug("🔍 [Auth] State:", {
      hasSession: !!session,
      loading,
      hasError: !!error,
    })

    if (session) {
      if (loading) return
      if (error) {
        console.info("❌ [Auth] Load failed:", error)
        return
      }
      if (!data?.isAdmin) {
        console.debug("👤 [Auth] Non-admin flow")
        if (
          !data?.integrations ||
          Object.keys(data?.integrations).length === 0 ||
          Object.values(data?.integrations).some(
            (integration) => integration.status === "pending"
          )
        ) {
          console.info("⏳ [Auth] Pending integrations")
          setIsAuthorized(2)
        } else {
          console.info("🚫 [Auth] Access denied")
          setIsAuthorized(0)
        }
      } else {
        console.info("✅ [Auth] Admin access granted")
        setIsAuthorized(1)
      }
    }
  }, [session, data, loading, error])

  useEffect(() => {
    console.debug("🔄 [Auth] Status:", {isAuthorized})

    let pollInterval: NodeJS.Timeout | null = null

    if (isAuthorized === 2) {
      console.debug("🔄 [Auth] Starting integration poll")
      pollInterval = setInterval(() => {
        refetch()
      }, 5000)
    }

    return () => {
      if (pollInterval) {
        console.debug("⏹️ [Auth] Stopping poll")
        clearInterval(pollInterval)
      }
    }
  }, [isAuthorized, refetch])

  if (isAuthorized === null) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Loading...
      </div>
    ) // or a loading spinner
  }

  if (isAuthorized === 2) {
    navigate("/auth/onboarding/configure_integrations")
  }

  if (isAuthorized === 3) {
    return <AuthError type="not_logged_in" pageContainer={true} />
  }

  if (isAuthorized === 0) {
    return <AuthError type="not_admin" pageContainer={true} />
  }

  return children
}

export default AuthenticatedRouteProvider

// callback for magic link login. If the user has an admin link, then they are redirected to /archive.
// If they do not have a link, then the page will just say "thank you"

// this is the callback page for the magic link login.

import React, {useEffect} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import UnifiedDirectory from "@unified-api/react-directory"
import Logo from "@/components/general/Logo"
import {Button, Input} from "@nextui-org/react"
import AuthError from "@/components/auth/AuthError"
import {useUser} from "@/contexts/UserContextProvider"
import {registerUserIntegration} from "@/utils/api/functions/register-user-integration"
import {checkAdmin} from "@/utils/auth"
// Initialize Supabase client

const unifiedWorkspaceId = process.env.REACT_APP_UNIFIED_WORKSPACE_ID
const redirectUrl = process.env.REACT_APP_REDIRECT_URL
const quartzNumber = process.env.REACT_APP_ORG_PHONE_NUMBER

interface UnifiedState {
  auth_user_id: string
  nonce: string
  timestamp: number
  sig: string
}

function convertToQuartzFormat(phoneNumber: string) {
  // converts number xxxxxxxxxx to +1 (xxx) xxx-xxxx
  return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
}

const PhoneNumberIntegration = ({
  phoneNumber,
  setPhoneNumber,
  errorMessage,
  submitStatus,
  handleSubmit,
  session,
}: {
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  errorMessage: string
  submitStatus: string
  handleSubmit: (session: any) => void
  session: any
}) => {
  return (
    <>
      <Logo type="svg" size={20} />
      <h1>Enter your phone number to confirm your account</h1>
      <Input
        label="Enter your phone number"
        variant="bordered"
        placeholder=""
        type="tel"
        value={phoneNumber}
        // isInvalid={!validatePhoneNumber(phoneNumber)}
        // isInvalid={!isValid}
        onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}
        startContent={
          "+"
          // <Dropdown>
          //   <DropdownTrigger>+_</DropdownTrigger>
          //   <DropdownMenu>
          //     <DropdownItem>
          //       <div className="flex items-center gap-1">
          //         +1 US/Canada
          //       </div>
          //     </DropdownItem>
          //     <DropdownItem>+44 UK</DropdownItem>
          //     <DropdownItem>+33 France</DropdownItem>
          //   </DropdownMenu>
          // </Dropdown>
        }
      />
      {errorMessage && <p className="text-xs text-red-500">{errorMessage}</p>}
      <Button
        onClick={() => handleSubmit(session)}
        isLoading={submitStatus === "LOADING"}
      >
        Submit
      </Button>
    </>
  )
}

const CallbackIntegrationWrapper = ({state}: {state: UnifiedState | null}) => {
  // const encodedState = Buffer.from(JSON.stringify(state)).toString('base64');
  // btoa is a browser-compatible version of Buffer encoding
  if (!state) {
    return <div>Loading...</div>
  }
  const encodedState = btoa(JSON.stringify(state))

  // const authUrl = `https://api.unified.to/unified/integration/auth/${workspace_id}/${integration}?state=${encodedState}`;
  const currentUrl = window.location.href

  return (
    <>
      <Logo type="svg" size={20} />
      <h1 className="text-xl font-medium">Welcome to Quartz Compliance</h1>
      <p className="text-xs">
        Your compliance team has requested you to integrate the following
        accounts to your quartz organization.{" "}
      </p>
      <UnifiedDirectory
        workspace_id={unifiedWorkspaceId}
        success_redirect={currentUrl}
        failure_redirect={currentUrl}
        environment={"Sandbox"}
        state={encodedState}
        external_xref={state.auth_user_id || ""}
      />
    </>
  )
}

function generateRandomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

function generateSignature() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

function decodeState(encodedState: string) {
  const decodedState = atob(encodedState)
  return JSON.parse(decodedState)
}

// invite-admin or invite-user // re-invite-user

// http://localhost:3000/auth/callback?
//  token_hash=83959c02ea8be4e9873e71ce0a5eead7bf42fb82f57d90f8a16acd66#
// &id=6797e73eaeda387dacc8ac7d
// &state=eyJhdXRoX3VzZXJfaWQiOiJmZTI5OGRkZS00NmI4LTRlNDQtOTNmYi1lMTFjZmQxZmQ0MTgiLCJ1c2VyX2lkIjoiZDYyMGIxNDAtODM5My00NDgzLTk5NmEtODg5NGI4ODNlMDlmIiwibm9uY2UiOiJxbGZzdXV0anl0YWQ1a3Q1NDA5eGl2IiwidGltZXN0YW1wIjoxNzM4MDA4Mzc1NTc0LCJzaWciOiJ6dDVvaTNoY3FjNWt3NXBpMmU2MWYiLCJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW10cFpDSTZJbTlhUmt4MGVucFpNWHBoZW5KUFdFMGlMQ0owZVhBaU9pSktWMVFpZlEuZXlKcGMzTWlPaUpvZEhSd2N6b3ZMM2g1WW5ScGJuRmhabTUxY1cxMGVtRmpjbkp4TG5OMWNHRmlZWE5sTG1OdkwyRjFkR2d2ZGpFaUxDSnpkV0lpT2lKbVpUSTVPR1JrWlMwME5tSTRMVFJsTkRRdE9UTm1ZaTFsTVRGalptUXhabVEwTVRnaUxDSmhkV1FpT2lKaGRYUm9aVzUwYVdOaGRHVmtJaXdpWlhod0lqb3hOek00TURFeE9USTRMQ0pwWVhRaU9qRTNNemd3TURnek1qZ3NJbVZ0WVdsc0lqb2ljMmhoYm5SaGJuVXJNREF3TkVCdlluTnBaR2xoYm14aFluTXVZMjhpTENKd2FHOXVaU0k2SWlJc0ltRndjRjl0WlhSaFpHRjBZU0k2ZXlKd2NtOTJhV1JsY2lJNkltVnRZV2xzSWl3aWNISnZkbWxrWlhKeklqcGJJbVZ0WVdsc0lsMTlMQ0oxYzJWeVgyMWxkR0ZrWVhSaElqcDdJbVZ0WVdsc1gzWmxjbWxtYVdWa0lqcDBjblZsZlN3aWNtOXNaU0k2SW1GMWRHaGxiblJwWTJGMFpXUWlMQ0poWVd3aU9pSmhZV3d4SWl3aVlXMXlJanBiZXlKdFpYUm9iMlFpT2lKdmRIQWlMQ0owYVcxbGMzUmhiWEFpT2pFM016Z3dNRGd6TWpoOVhTd2ljMlZ6YzJsdmJsOXBaQ0k2SWpKaFl6SmpORGhsTFRCbFl6UXRORFpoWVMxaFpEbGhMV0UzTUdSbU56YzBNekExWkNJc0ltbHpYMkZ1YjI1NWJXOTFjeUk2Wm1Gc2MyVjkuSTVXTHZTRk11aFROQy1YbEJMU09rbmxkcDB2MHk3REIzSnJ5WXVEOG1YWSJ9
// &type=microsoftoutlook

// add listener to onsupabaseauthstatechange, check if the user has completed their onboarding
//

export const ConfigureIntegrations = () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const [error, setError] = React.useState(false)

  const [searchParams] = useSearchParams()

  const [submitStatus, setSubmitStatus] = React.useState<
    "FALSE" | "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  >("IDLE")

  const {session, loading} = useUser()

  console.log("session in configure integrations", session)

  const unifiedStateRef = React.useRef<UnifiedState | null>({
    auth_user_id: session?.user?.id,
    nonce: generateRandomString(),
    timestamp: Date.now(),
    sig: generateSignature(),
  })

  useEffect(() => {
    const handleCallback = async () => {
      console.info("Callback for configure integrations")
      const encodedState = searchParams.get("state")
      const id = searchParams.get("id")
      const type = searchParams.get("type")

      // decode the state
      if (encodedState) {
        unifiedStateRef.current = decodeState(encodedState)
        try {
          await registerUserIntegration({
            provider: "unified/outlook", // TODO: We should do this programmatically via org integration config, but hardcoding for now
            channel: "email",
            externalConnectionId: id,
            orgId: null,
            session: session,
          })
          setSubmitStatus("SUCCESS")
        } catch (error) {
          console.error("Error during integration registration:", error)
          // setError(true)
        }
      }
      // register user for the integration
    }
    if (searchParams.size > 0) {
      handleCallback()
    }
  }, [searchParams, session])

  useEffect(() => {
    checkAdmin(session).then((isAdmin) => {
      if (isAdmin) {
        if (submitStatus === "SUCCESS") {
          navigate("/archive")
        }
      }
    })
  }, [submitStatus, session, navigate])

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      {error ? (
        <AuthError type="invite_expired" />
      ) : (
        <div className="bg-onyx border-onyx-200 flex w-full max-w-fit flex-col gap-4 rounded-lg border p-4">
          {submitStatus !== "SUCCESS" ? (
            <CallbackIntegrationWrapper state={unifiedStateRef.current} />
          ) : (
            <>
              <h1>
                Thank you for confirming! You are now logged into the quartz
                network
              </h1>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Logo type="svg" size={16} />
                  <p className="text-sm font-medium">
                    Your Demo Quartz number to add to chats is:
                  </p>
                </div>
                <p className="text-sm underline">
                  {convertToQuartzFormat(quartzNumber)}
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

import supabase from "@/supabaseConfig"

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const removeUser = async ({
  authUserToRemove,
  session,
}: {
  authUserToRemove: string
  session: any
}): Promise<void> => {
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  const token = session
  if (!session || !session.access_token) {
    throw new Error("Failed to retrieve valid session.")
  }

  const response = await fetch(`${supabaseUrl}/functions/v1/remove-user`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    },
    body: JSON.stringify({authUserToRemove}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }
}

import {createContext, useContext, useEffect, useState, useMemo} from "react"
import supabase from "../supabaseConfig"
import {Profile} from "../global.types"
import {User, Session} from "@supabase/supabase-js"
import {Navigate, useNavigate} from "react-router-dom"
import {ApolloProvider} from "@apollo/client"
import {getApolloClient} from "@/apolloConfig"

interface UserContextType {
  user: User | null
  // profile: Profile | null
  session: Session | null
  loading: boolean
  error: Error | null
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export function UserContextProvider({children}: {children: React.ReactNode}) {
  const [user, setUser] = useState<User | null>(null)
  // const [profile, setProfile] = useState<Profile | null>(null)
  const [session, setSession] = useState<Session | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [client, setClient] = useState(getApolloClient())

  // const fetchProfile = async (userId: string) => {
  //   try {
  //     console.log("Fetching profile for userId:", userId)
  //     const {data: profileData, error: profileError} = await supabase
  //       .from("profiles")
  //       .select("*")
  //       .eq("auth_user_id", userId)
  //       .limit(1)
  //       .single()

  //     console.log("profileData", profileData)

  //     if (profileError) {
  //       console.error("Profile fetch error:", profileError.message)
  //       throw new Error(profileError.message)
  //     }

  //     // if (profileData.role !== "ADMIN") {
  //     //   navigate("/")
  //     // }

  //     setProfile(profileData)
  //   } catch (error) {
  //     console.error("Error in fetchProfile:", error)
  //     setError(
  //       error instanceof Error
  //         ? error
  //         : new Error("An unknown error occurred fetching profile")
  //     )
  //   }
  // }

  useEffect(() => {
    // Get initial session
    supabase.auth
      .getSession()
      .then(({data: {session}}) => {
        setSession(session)
        setUser(session?.user)
        // Initialize Apollo Client with current session
        setClient(getApolloClient())
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })

    // Listen for auth changes
    const {
      data: {subscription},
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  // useEffect(() => {
  //   // if user is set, fetch profile
  //   if (user) {
  //     const profile = fetchProfile(user.id)
  //     console.log("profile", profile)
  //     // const isOnboarded = checkUserOnboarded({userId: user.id})
  //   }
  // }, [user])

  useEffect(() => {
    // Create new Apollo Client instance when auth state changes
    setClient(getApolloClient())
  }, [session])

  const contextValue = {
    user,
    // profile,
    session,
    loading,
    error,
  }

  return (
    <UserContext.Provider value={contextValue}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used within a UserContextProvider")
  }
  return context
}

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const getOrgID = async (session: any = null): Promise<string> => {
  const token = session
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }
  if (!token?.access_token) {
    throw new Error("No access token found.")
  }

  const query = `
    query getProfilesOrgId{
  profilesCollection (first: 1) {
    edges {
      node {
        org_id
      }
    }
  }
}
  `

  const response = await fetch(`${supabaseUrl}/graphql/v1`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    },
    body: JSON.stringify({query}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }

  const data = await response.json()
  const ext = data.data.profilesCollection.edges.map(
    (edge: {node: {org_id: string}}) => edge.node.org_id
  )
  return ext[0]
}

export default UserContextProvider

import React, {useEffect} from "react"
import {cn, ListboxSection} from "@nextui-org/react"

import threadAPIstub from "../data/threadAPIstub.json"

import {Button, Listbox, ListboxItem, Tooltip} from "@nextui-org/react"
import {
  IconBook,
  IconBug,
  IconCalendar,
  IconChevronRight,
  IconCircleDashedCheck,
  IconCircleDashedX,
  IconDeviceWatchHeart,
  IconFilter,
  IconGitPullRequest,
  IconInputAi,
  IconLayout,
  IconLock,
  IconMessage,
  IconPlaystationCircle,
  IconProgressAlert,
  IconProgressCheck,
  IconTableExport,
  IconTag,
  IconUser,
  IconX,
} from "@tabler/icons-react"

import {twMerge} from "tailwind-merge"
import {Input} from "@nextui-org/react"

import {
  getThreadAPI,
  getThreadsByMonthMap,
  Profile,
  Thread,
  ThreadWithParticipants,
} from "@/utils/api/gqpSB/getThreadAPI"
import {getNumberOfUsers} from "@/utils/api/gqpSB/getUsers"
import {getOrgID, useUser} from "@/contexts/UserContextProvider"
import {DataLoader} from "@/components/general/ui/DataLoader"
import {LockedFeatureIndicator} from "@/components/general/ui/LockedFeatureIndicator"
import {GetThreadsDocument} from "@/gql/graphql"
// import { useGetThreads } from "@/utils/graphQL/queries/useGetThreads"
import {useQuery} from "@apollo/client"
import MessageView from "@/components/archive/MessageView"
import EmailView from "@/components/archive/EmailView"
import {useSearchThreads} from "@/utils/api/search/searchMessages"
import {useMemo} from "react"
import {useDebounce} from "@/hooks/useDebounce"

export const ItemCounter = ({number}) => (
  <div className="flex items-center gap-1 text-default-400">
    <span className="text-small">{number}</span>
    <IconChevronRight className="text-xl" />
  </div>
)

export const IconWrapper = ({children, className}) => (
  <div
    className={cn(
      className,
      "flex h-7 w-7 items-center justify-center rounded-small"
    )}
  >
    {children}
  </div>
)

export function ListComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      onAction={(key) => alert(key)}
      className="max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="issues"
        // endContent={<ItemCounter number={13} />}
        startContent={
          <IconWrapper className="bg-success/10 text-success">
            <IconBug className="text-lg " />
          </IconWrapper>
        }
      >
        Issues
      </ListboxItem>
      <ListboxItem
        key="pull_requests"
        // endContent={<ItemCounter number={6} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconGitPullRequest className="text-lg " />
          </IconWrapper>
        }
      >
        Pull Requests
      </ListboxItem>
      <ListboxItem
        key="discussions"
        // endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Discussions
      </ListboxItem>
      <ListboxItem
        key="actions"
        // endContent={<ItemCounter number={2} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconPlaystationCircle className="text-lg " />
          </IconWrapper>
        }
      >
        Actions
      </ListboxItem>
      <ListboxItem
        key="projects"
        endContent={<ItemCounter number={4} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconLayout className="text-lg " />
          </IconWrapper>
        }
      >
        Projects
      </ListboxItem>
      <ListboxItem
        key="releases"
        className="group h-auto py-3"
        endContent={<ItemCounter number={399} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconTag className="text-lg" />
          </IconWrapper>
        }
        textValue="Releases"
      >
        <div className="flex flex-col gap-1">
          <span>Releases</span>
          <div className="rounded-small bg-default-100 px-2 py-1 group-data-[hover=true]:bg-default-200">
            <span className="text-tiny text-default-600">
              @nextui-org/react@2.0.10
            </span>
            <div className="flex gap-2 text-tiny">
              <span className="text-default-500">49 minutes ago</span>
              <span className="text-success">Latest</span>
            </div>
          </div>
        </div>
      </ListboxItem>
      <ListboxItem
        key="contributors"
        // endContent={<ItemCounter number={79} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconUser />
          </IconWrapper>
        }
      >
        Contributors
      </ListboxItem>
      <ListboxItem
        key="watchers"
        // endContent={<ItemCounter number={82} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconDeviceWatchHeart />
          </IconWrapper>
        }
      >
        Watchers
      </ListboxItem>
      <ListboxItem
        key="license"
        endContent={<span className="text-small text-default-400">MIT</span>}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <IconBook />
          </IconWrapper>
        }
      >
        License
      </ListboxItem>
    </Listbox>
  )
}

export function DropdownMenuComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      // onAction={(key) => alert(key)}
      className="z-50 max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3  rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxSection title="Filter by:" className="mx-2 mt-2">
        <ListboxItem
          key="issues"
          // endContent={<ItemCounter number={13} />}
          startContent={
            <>
              <IconLock className="h-4 w-4 rounded-full bg-neutral-300 p-[0.125rem] text-gray-500" />
              <IconWrapper className="bg-success/10 text-success">
                <IconCalendar className="text-lg " />
              </IconWrapper>
            </>
          }
        >
          Date Ranges
        </ListboxItem>
        <ListboxItem
          key="pull_requests"
          // endContent={<ItemCounter number={6} />}
          startContent={
            <>
              <IconLock className="h-4 w-4 rounded-full bg-gray-200 p-[0.125rem] text-gray-500" />
              <IconWrapper className="bg-primary/10 text-primary">
                <IconInputAi className="text-lg " />
              </IconWrapper>
            </>
          }
        >
          Keywords
        </ListboxItem>
        {/* <ListboxItem
        key="discussions"
        endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Keywords
      </ListboxItem> */}
        <ListboxItem
          key="projects"
          // endContent={<ItemCounter number={4} />}
          startContent={
            <>
              <IconLock className="h-4 w-4 rounded-full bg-gray-200 p-[0.125rem] text-gray-500" />
              <IconWrapper className="bg-default/50 text-foreground">
                <IconLayout className="text-lg " />
              </IconWrapper>
            </>
          }
        >
          Cases
        </ListboxItem>
        {/* <ListboxItem
        key="releases"
        className="group h-auto py-3"
        endContent={<ItemCounter number={399} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconTag className="text-lg" />
          </IconWrapper>
        }
        textValue="Releases"
      >
        <div className="flex flex-col gap-1">
          <span>Releases</span>
          <div className="rounded-small bg-default-100 px-2 py-1 group-data-[hover=true]:bg-default-200">
            <span className="text-tiny text-default-600">
              @nextui-org/react@2.0.10
            </span>
            <div className="flex gap-2 text-tiny">
              <span className="text-default-500">49 minutes ago</span>
              <span className="text-success">Latest</span>
            </div>
          </div>
        </div>
      </ListboxItem> */}
        <ListboxItem
          key="contributors"
          // endContent={<ItemCounter number={79} />}
          startContent={
            <>
              <IconLock className="h-4 w-4 rounded-full bg-gray-200 p-[0.125rem] text-gray-500" />
              <IconWrapper className="bg-warning/10 text-warning">
                <IconUser />
              </IconWrapper>
            </>
          }
        >
          Users
        </ListboxItem>
      </ListboxSection>
    </Listbox>
  )
}

const Indicator = ({
  isProcessed,
  type,
  label,
  text,
  containerClass,
}: {
  isProcessed: boolean
  type: "status" | "processing"
  label?: string
  text?: string
  containerClass?: string
}) => {
  const color = isProcessed ? "bg-green-500" : "bg-red-500"
  return (
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {type === "status" ? (
        isProcessed ? (
          <IconCircleDashedCheck className="h-4 w-4" color="green" />
        ) : (
          <IconCircleDashedX className="h-4 w-4" color="red" />
        )
      ) : isProcessed ? (
        <IconProgressCheck className="h-4 w-4" color="green" />
      ) : (
        <IconProgressAlert className="h-4 w-4" color="orange" />
      )}
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
    // </Tooltip>
  )
}

const TableViewTicketItem = ({
  thread,
  setSelectedMessage,
  selectedMessage,
}: {
  thread: ThreadWithParticipants
  setSelectedMessage: (message: any) => void
  selectedMessage: Thread
}) => {
  const [selected, setSelected] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (selectedMessage?.thread_uid === thread.thread_uid) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [selectedMessage, thread])

  return (
    // <Checkbox
    //   className="relative w-full "
    //   classNames={{
    //     base: "w-full max-w-full m-0",
    //     label: "w-full max-w-full",
    //   }}
    //   onClick={() => setSelectedMessage(message)}
    // > // turn this on when we actually have something we need a checkbox for
    <div
      className={twMerge(
        "group relative cursor-pointer border-b border-l border-black/5  px-2 py-4 hover:bg-onyx-slate",
        selected ? "bg-onyx-slate px-4" : ""
      )}
      onClick={() => {
        setSelectedMessage(thread)
      }}
    >
      <div className="flex flex-col">
        <p className="text-sm font-medium">
          {thread.profiles && thread.profiles.length === 2
            ? thread.profiles
                .map(
                  (participant: any) =>
                    participant?.name || participant?.phone_number
                )
                .join(" & ")
            : thread.profiles
                .map(
                  (participant: any) =>
                    participant?.name || participant?.phone_number
                )
                .join(" , ")}
        </p>
        <p className="font-regular text-xs">{thread.title}</p>
        <p className="font-regular h-0 overflow-hidden text-xs group-hover:h-fit">
          {thread.description ||
            "Conversation Summaries are not available with your demo."}
        </p>
      </div>
      <div className="absolute right-0 top-0 z-10">
        <p className="font-regular p-1 text-xs">
          {new Date(thread.created_at).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </p>
      </div>
    </div>
    // </Checkbox>
  )
}

const TableView = ({
  threads,
  date,
  setSelectedMessage,
  selectedMessage,
}: {
  threads: ThreadWithParticipants[]
  date: string
  setSelectedMessage: (message: any) => void
  selectedMessage: Thread
}) => {
  return (
    <div className="grid w-full grid-cols-5">
      <div className="col-span-1 ">
        <p className="font-regular rounded-r-0 rounded-l-md bg-default-100 p-1 text-sm">
          {date}
        </p>
      </div>
      <div className="col-span-4 flex h-fit w-full flex-col gap-2 ">
        {/* <CheckboxGroup isDisabled> */}
        <div>
          {threads?.map((thread, idx) => (
            <TableViewTicketItem
              key={idx}
              thread={thread}
              setSelectedMessage={setSelectedMessage}
              selectedMessage={selectedMessage}
            />
          ))}
        </div>
        {/* </CheckboxGroup> */}
      </div>
    </div>
  )
}

const quartzNumber = process.env.REACT_APP_ORG_PHONE_NUMBER
function convertToQuartzFormat(phoneNumber: string) {
  // converts number xxxxxxxxxx to +1 (xxx) xxx-xxxx
  return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
}

function Archive() {
  // use effect
  // const [threads, setThreads] = React.useState<Thread[]>([])
  const [monthThreads, setMonthThreads] = React.useState<
    Map<string, ThreadWithParticipants[]>
  >(new Map())
  const [threadLoader, setThreadLoader] = React.useState<boolean>(true)
  // const [error, setError] = React.useState<string | null>(null)
  const [selectedThread, setSelectedThread] = React.useState<Thread | null>(
    null
  )

  const {user, profile, session} = useUser()
  const {data, loading, error, fetchMore} = useQuery(GetThreadsDocument)
  const [searchTerm, setSearchTerm] = React.useState<string>("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const {
    isLoading,
    error: searchError,
    threads,
    searchResults,
  } = useSearchThreads(debouncedSearchTerm, 0, 10)

  useEffect(() => {
    console.log("searchResults!!!", searchResults)
  }, [searchResults])

  // console.log("user", user)
  // console.log("profile", profile)

  // useEffect(() => {
  //   async function fetchThreads() {
  //     try {
  //       setThreadLoader(true)
  //       setError(null)

  //       // const data = await getThreads()
  //       // const data = threadAPIstub
  //       // const orgID = profile?.org_id
  //       if (session?.access_token) {
  //         const orgID = await getOrgID(session)

  //         if (!orgID) {
  //           const data = threadAPIstub
  //           setThreads(data as unknown as Thread[])
  //         } else {
  //           const data = await getThreadAPI(orgID, session)
  //           setThreads(data)
  //           console.log(data)
  //         }

  //         // const fetchedThreads = await getThreadDocsWithParticipants()
  //         // setThreads(fetchedThreads)
  //         // console.log(fetchedThreads)
  //       }
  //     } catch (err) {
  //       console.error("Error fetching threads:", err)
  //       setError("Failed to load threads. Please try again later.")
  //     } finally {
  //       setThreadLoader(false)
  //     }
  //   }

  //   fetchThreads()
  // }, [user, profile, session])

  // React.useEffect(() => {
  //   if (threads?.length > 0) {
  //     // const tmp = getThreadsByMonthMap(threads)
  //     // const mt = new Map<string, ThreadsWithParticipants[]>()
  //     const threadsWithParticipants: ThreadWithParticipants[] = []
  //     threads.forEach((thread) => {
  //       const profiles = new Map<string, Profile>()
  //       thread.messages.forEach((message) => {
  //         if (message?.profiles)
  //           profiles.set(message?.profiles?.phone_number, message?.profiles)
  //         else {
  //           const cn = message?.extraction_message?.contact_name
  //           const pn = "NONE"
  //           const uid = crypto.randomUUID()
  //           profiles.set(uid, {
  //             name: cn,
  //             phone_number: pn,
  //             email: null,
  //             title: null,
  //             org_id: thread.org_id,
  //             company: null,
  //             user_id: uid,
  //             is_active: true,
  //             created_at: new Date().toISOString(),
  //             auth_user_id: uid,
  //           })
  //         }
  //       })
  //       threadsWithParticipants.push({
  //         ...thread,
  //         participants: Array.from(profiles.values()),
  //       })
  //     })
  //     const mt = getThreadsByMonthMap(threadsWithParticipants)

  //     setMonthThreads(mt as Map<string, ThreadWithParticipants[]>)
  //     setThreadLoader(false)
  //   }
  // }, [threads])

  // the selected thread array of profiles
  const [selectedThreadGP, setSelectedThreadGP] = React.useState<Profile[]>([])

  function selectThread(thread: Thread) {
    setSelectedThread(thread)
    // iterate through the selected thread an set selectedThreadGP to all of the group profiles
    // Changed from array to Set to ensure uniqueness of profiles
    const profiles = new Map<string, Profile>()
    thread.messages.forEach((message) => {
      if (message?.profiles)
        profiles.set(message?.profiles?.phone_number, message?.profiles)
      else {
        const cn = message?.extraction_message?.contact_name
        const pn = "NONE"
        const uid = crypto.randomUUID()
        profiles.set(uid, {
          name: cn,
          phone_number: pn,
          email: null,
          title: null,
          org_id: thread.org_id,
          company: null,
          user_id: uid,
          is_active: true,
          created_at: new Date().toISOString(),
          auth_user_id: uid,
        })
      }
    })
    setSelectedThreadGP(Array.from(profiles.values()))
  }
  // create a map from messages that has month as the key and the messages as the value

  React.useEffect(() => {
    if (!session) return
    getNumberOfUsers(session).then((numberOfUsers) => {
      setNumberOfUsers(numberOfUsers)
    })
  }, [session])
  const [numberOfUsers, setNumberOfUsers] = React.useState<number>(0)

  const [showFilters, setShowFilters] = React.useState<boolean>(false)
  const [userDocMap, setUserDocMap] = React.useState<Map<string, any>>(
    new Map()
  )
  // useEffect(() => {
  //   getUserDocMap().then((userDocMap) => {
  //     setUserDocMap(userDocMap)
  //   })
  //   console.log(userDocMap)
  // }, [])

  return (
    <div className="animate-fade animate-once animate-ease-linear mr-4 flex w-full grid-cols-7 justify-between gap-4 rounded-md border-black/5 bg-onyx-white p-4  transition-all duration-150">
      <div className=" col-span-4 flex h-full w-full min-w-[30%] flex-col justify-between overflow-scroll bg-white scrollbar-hide">
        <div className="sticky top-0 z-50 flex flex-col justify-start gap-2 bg-white">
          <div className="z-50 flex w-full flex-row items-center justify-between gap-2 bg-white">
            <div className="flex flex-col gap-2 bg-white">
              <Indicator
                isProcessed={true}
                type="status"
                label="Dedicated Quartz Number"
                text={convertToQuartzFormat(quartzNumber)}
              />
              <Indicator
                isProcessed={true}
                type="status"
                label={`${numberOfUsers} Whitelisted Users`}
              />
            </div>
            {/* <Dropdown
              className="self-end"
              classNames={{
                base: "w-full",
                content: "border-2 border-blue-500",
              }}
            >
              <DropdownTrigger className="group"> */}
            <Button isIconOnly>
              <Tooltip content="Export Report is Locked">
                <div className="flex flex-row items-center gap-2">
                  <IconTableExport className="h-4 w-4" />
                  {/* <p>Export</p> */}
                </div>
              </Tooltip>
            </Button>
            {/* </DropdownTrigger>
              <DropdownMenu closeOnSelect={false}> */}
            {/* <DropdownItem closeOnSelect={false} key="select">
                  <Switch
                    size="sm"
                    className="flex flex-row items-center gap-1 text-xs"
                  >
                    <p>Export with Filters</p>
                  </Switch>
                </DropdownItem> */}

            {/* <DropdownItem>
                  <div className="flex flex-row items-center gap-2">
                    <IconFileTypeCsv className="h-4 w-4" />
                    Export to CSV
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <div className="flex flex-row items-center gap-2">
                    <IconFileTypePdf className="h-4 w-4" />
                    Export to PDF
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <div className="flex flex-row items-center gap-2">
                    <IconFileExcel className="h-4 w-4" />
                    Export to Excel
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </div>
          <div className="relative z-20 h-8 bg-white">
            <Button
              className="absolute right-0 top-0"
              variant="bordered"
              color="primary"
              size="sm"
              radius="full"
              onClick={() => setShowFilters(!showFilters)}
            >
              <IconFilter className="h-4 w-4" />
              <p>All</p>
            </Button>

            {showFilters && (
              <div className="absolute right-2 top-10 z-50">
                <DropdownMenuComponent />
              </div>
            )}
          </div>
          <Tooltip content="This feature is for trial sandbox users">
            <div className="w-full">
              <Input
                classNames={{
                  base: "w-full",
                  inputWrapper: "border-2 border-blue-500",
                }}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <p className="font-regular mb-2 ml-[0.375rem] mt-[0.125rem] select-none text-[8px]">
                Search by name, phone number, email, or type a prompt and Quartz
                will find examples of it (i.e. "Report of occurances where
                converations clearly are missing context signalling conversation
                off channel")
              </p>
            </div>
          </Tooltip>
        </div>
        <div className="z-10 min-h-[80%]">
          {/* <p>Table</p> */}
          {threadLoader && <DataLoader />}
          {monthThreads.size < 1 && <p>No threads</p>}
          {monthThreads.size > 0 &&
            Array.from(monthThreads.entries()).map(([date, thread], idx) => {
              return (
                <TableView
                  key={idx}
                  threads={thread}
                  date={date}
                  setSelectedMessage={selectThread}
                  selectedMessage={selectedThread}
                />
              )
            })}
        </div>
      </div>
      <EmailView
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        selectedThreadGP={selectedThreadGP}
      />
    </div>
  )
}

export default Archive

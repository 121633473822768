// import * as React from 'react'

// export default function LogoHeader() {
//     return (
//         <div className="flex flex-row gap-1.5 whitespace-nowrap ">
//             <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/a3898f72bd60568e6c94ecf9e138c500605f3b99ab21135fe469080a39785696?"
//                 className="aspect-square w-8 shrink-0"
//             />

//             <p className="font-monaspaceKrypton align-bottom text-2xl font-bold tracking-tight">
//                 ONYX
//             </p>
//         </div>
//     )
// }

import React from "react"
import Logo from "./Logo"

const LogoHeader: React.FC = () => {
  return (
    <div className="flex gap-1.5 p-2 px-4 text-2xl font-bold tracking-tight text-onyx-black">
      <Logo type="img" />
      <div className="mt-1 font-monaspaceKrypton ">QUARTZ</div>
    </div>
  )
}

export default LogoHeader

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const registerUserIntegration = async ({
  provider,
  channel,
  externalConnectionId,
  orgId,
  session,
}: {
  provider: "unified/outlook"
  channel: "email" | "sms"
  externalConnectionId: string
  orgId: string | null
  session: any
}): Promise<void> => {
  const token = session
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  if (!token) {
    throw new Error("No token found in session.")
  }
  console.log("regsitering user integration")

  const response = await fetch(
    `${supabaseUrl}/functions/v1/register-user-integration`,
    {
      method: "POST",
      headers: {
        apiKey: apiKey,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.access_token}`,
      },
      body: JSON.stringify({provider, channel, externalConnectionId, orgId}),
    }
  )

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }
}

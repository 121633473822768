import {useEffect, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import supabase from "../../../supabaseConfig"
import {AuthApiError} from "@supabase/supabase-js"
import AuthError from "@/components/auth/AuthError"

export default function MagicLinkCallback() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchSession = async () => {
      const token_hash = searchParams.get("token_hash")

      if (!token_hash) {
        console.error("Missing token_hash or type in URL parameters")
        navigate("/auth/login")
        return
      }

      try {
        const {data, error} = await supabase.auth.verifyOtp({
          token_hash,
          type: "magiclink",
        })

        if (data.session) {
          navigate("/archive")
        } else if (error) {
          console.error("Error verifying OTP:", error.message)
          // Check if the error message indicates an expired or invalid link
          if (error instanceof AuthApiError) {
            setError(true)
            // navigate("/auth/login")
          } else {
            // navigate("/auth/login") // Default error navigation
            navigate("/login")
          }
        }
      } catch (error) {
        console.error("Exception when verifying OTP:", error)
        navigate("/auth/login")
      }
    }

    fetchSession()
  }, [navigate, searchParams])

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      {error ? (
        <AuthError type="login_timeout" />
      ) : (
        <div>Confirming your login...</div>
      )}
    </div>
  )
}

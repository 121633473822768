import React from "react"
import {Chip, cn, ListboxSection} from "@nextui-org/react"
import {Button, Listbox, ListboxItem} from "@nextui-org/react"
import {
  IconAlertSquareRoundedFilled,
  IconBook,
  IconBug,
  IconCalendar,
  IconChevronRight,
  IconCircleDashedCheck,
  IconCircleDashedX,
  IconDeviceWatchHeart,
  IconGitPullRequest,
  IconInputAi,
  IconLayout,
  IconMessage,
  IconPlaystationCircle,
  IconProgressAlert,
  IconProgressCheck,
  IconTag,
  IconUser,
} from "@tabler/icons-react"
import * as TablerIcons from "@tabler/icons-react"

import {twMerge} from "tailwind-merge"
import {Input} from "@nextui-org/react"

const IconComponent = ({
  iconNumber,
  color,
}: {
  iconNumber: number
  color: string
}) => {
  const iconKey = `IconNumber${iconNumber}Small` as keyof typeof TablerIcons

  const IconComponent = (TablerIcons[iconKey] ||
    TablerIcons.IconNumber0Small) as React.ComponentType<any>
  return <IconComponent color={color || "#000000"} size={18} />
}

export const ItemCounter = ({number}) => (
  <div className="flex items-center gap-1 text-default-400">
    <span className="text-small">{number}</span>
    <IconChevronRight className="text-xl" />
  </div>
)

export const IconWrapper = ({children, className}) => (
  <div
    className={cn(
      className,
      "flex h-7 w-7 items-center justify-center rounded-small"
    )}
  >
    {children}
  </div>
)

export function ListComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      onAction={(key) => alert(key)}
      className="max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="issues"
        endContent={<ItemCounter number={13} />}
        startContent={
          <IconWrapper className="bg-success/10 text-success">
            <IconBug className="text-lg " />
          </IconWrapper>
        }
      >
        Issues
      </ListboxItem>
      <ListboxItem
        key="pull_requests"
        endContent={<ItemCounter number={6} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconGitPullRequest className="text-lg " />
          </IconWrapper>
        }
      >
        Pull Requests
      </ListboxItem>
      <ListboxItem
        key="discussions"
        endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Discussions
      </ListboxItem>
      <ListboxItem
        key="actions"
        endContent={<ItemCounter number={2} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconPlaystationCircle className="text-lg " />
          </IconWrapper>
        }
      >
        Actions
      </ListboxItem>
      <ListboxItem
        key="projects"
        endContent={<ItemCounter number={4} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconLayout className="text-lg " />
          </IconWrapper>
        }
      >
        Projects
      </ListboxItem>
      <ListboxItem
        key="releases"
        className="group h-auto py-3"
        endContent={<ItemCounter number={399} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconTag className="text-lg" />
          </IconWrapper>
        }
        textValue="Releases"
      >
        <div className="flex flex-col gap-1">
          <span>Releases</span>
          <div className="rounded-small bg-default-100 px-2 py-1 group-data-[hover=true]:bg-default-200">
            <span className="text-tiny text-default-600">
              @nextui-org/react@2.0.10
            </span>
            <div className="flex gap-2 text-tiny">
              <span className="text-default-500">49 minutes ago</span>
              <span className="text-success">Latest</span>
            </div>
          </div>
        </div>
      </ListboxItem>
      <ListboxItem
        key="contributors"
        endContent={<ItemCounter number={79} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconUser />
          </IconWrapper>
        }
      >
        Contributors
      </ListboxItem>
      <ListboxItem
        key="watchers"
        endContent={<ItemCounter number={82} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconDeviceWatchHeart />
          </IconWrapper>
        }
      >
        Watchers
      </ListboxItem>
      <ListboxItem
        key="license"
        endContent={<span className="text-small text-default-400">MIT</span>}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <IconBook />
          </IconWrapper>
        }
      >
        License
      </ListboxItem>
    </Listbox>
  )
}

export function DropdownMenuComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      onAction={(key) => alert(key)}
      className="z-50 max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxSection title="Filter by:" className="ml-2 mt-2">
        <ListboxItem
          key="issues"
          endContent={<ItemCounter number={13} />}
          startContent={
            <IconWrapper className="bg-success/10 text-success">
              <IconCalendar className="text-lg " />
            </IconWrapper>
          }
        >
          Date Ranges
        </ListboxItem>
        <ListboxItem
          key="pull_requests"
          endContent={<ItemCounter number={6} />}
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <IconInputAi className="text-lg " />
            </IconWrapper>
          }
        >
          Keywords
        </ListboxItem>
        {/* <ListboxItem
        key="discussions"
        endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Keywords
      </ListboxItem> */}
        <ListboxItem
          key="projects"
          endContent={<ItemCounter number={4} />}
          startContent={
            <IconWrapper className="bg-default/50 text-foreground">
              <IconLayout className="text-lg " />
            </IconWrapper>
          }
        >
          Cases
        </ListboxItem>
        <ListboxItem
          key="contributors"
          endContent={<ItemCounter number={79} />}
          startContent={
            <IconWrapper className="bg-warning/10 text-warning">
              <IconUser />
            </IconWrapper>
          }
        >
          Users
        </ListboxItem>
      </ListboxSection>
    </Listbox>
  )
}

const Indicator = ({
  isProcessed,
  type,
  label,
  text,
  containerClass,
}: {
  isProcessed: boolean
  type: "status" | "processing"
  label?: string
  text?: string
  containerClass?: string
}) => {
  const color = isProcessed ? "bg-green-500" : "bg-red-500"
  return (
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {type === "status" ? (
        isProcessed ? (
          <IconCircleDashedCheck className="h-4 w-4" color="green" />
        ) : (
          <IconCircleDashedX className="h-4 w-4" color="red" />
        )
      ) : isProcessed ? (
        <IconProgressCheck className="h-4 w-4" color="green" />
      ) : (
        <IconProgressAlert className="h-4 w-4" color="orange" />
      )}
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
    // </Tooltip>
  )
}

const AlertItem = ({
  alert,
}: {
  alert: {
    description?: string
    type: string
    message: string
    number_of_conversations?: number
  }
}) => {
  return (
    <div className="z-10 flex flex-row items-center gap-2 bg-white">
      <div className="flex flex-row gap-2 rounded-lg border border-red-500/5  p-1">
        <IconAlertSquareRoundedFilled size={20} color="#ef4444" />
      </div>
      <div className="flex w-full flex-row items-center gap-2">
        <div
          className={twMerge(
            `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`
          )}
        ></div>
        <div
          className={twMerge(
            `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`
          )}
        >
          {alert.type === "Platform Subversion" ? (
            <TablerIcons.IconGitBranchDeleted size={20} color="#707070" />
          ) : alert.type === "Potential Compliance Violation" ? (
            <TablerIcons.IconReportMoney size={20} color="#707070" />
          ) : (
            <TablerIcons.IconClock size={20} color="#707070" />
          )}
          <p className="text-xs font-medium">{alert.type}</p>
        </div>
        <p className="text-sm">{alert.message}</p>
      </div>
      <div className="flex w-full flex-row items-center  justify-end">
        <Chip
          title="Generate Report"
          variant="flat"
          color="primary"
          className="m-2 max-h-fit w-fit max-w-fit  p-0"
          //   endContent={<IconChevronRight size={14} color="dodgerblue" />}
        >
          <p className="text-xs font-medium">Generate Report</p>
        </Chip>
        <Chip
          title="See Conversations"
          variant="flat"
          color="warning"
          className="m-2 max-h-fit w-fit max-w-fit  p-0"
        >
          <p className="text-xs font-medium">
            Open Conversations{" "}
            {alert.number_of_conversations
              ? `- (${alert.number_of_conversations})`
              : ""}
          </p>
        </Chip>
        <p className="text-sm">{new Date().toLocaleString()}</p>
      </div>
    </div>
  )
}

function Alerts() {
  const [showAlerts, setShowAlerts] = React.useState<boolean>(true)
  return (
    <div className="animate-fade animate-once animate-ease-linear mr-4 flex w-full grid-cols-7 flex-col justify-between gap-4 rounded-md border-black/5 bg-onyx-white p-4  transition-all duration-150">
      <div
        className={twMerge(
          "flex max-h-fit w-full flex-col  transition-all duration-150 scrollbar-hide",
          showAlerts ? "h-[100%] overflow-visible" : "h-10 overflow-clip"
        )}
      >
        <div className="flex flex-row justify-end">
          <Button
            variant="flat"
            color="secondary"
            className="m-2 max-h-fit w-fit max-w-fit p-0"
            onClick={() => {
              setShowAlerts(!showAlerts)
            }}
          >
            <Indicator
              isProcessed={true}
              type="status"
              label="Alerts Control"
            ></Indicator>
          </Button>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-md border border-black/5 bg-onyx-slate/15 p-2 py-1 bg-grid-small-onyx-black/15">
          <div className="flex flex-col gap-0 px-2 py-1">
            <p className="text-lg font-medium">Set Alerts</p>
            <p className="text-sm">
              Set alerts that will notify your team under certain conditions or
              violations. Quartz will look for compliance violations or
              suspicious activity.
            </p>
          </div>
          <div className="flex flex-col gap-2 border-b border-black/5 px-2 pb-2">
            <p className="text-sm font-medium">Alert Policies:</p>
          </div>
          <Input
            // label="Alert Me When:"
            startContent={
              <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
                <IconComponent iconNumber={1} color="#ffffff" />
              </div>
            }
            placeholder="someone mentions a stock ticker"
            value="someone mentions a stock ticker"
          ></Input>
          <Input
            // label="Alert Me When:"
            placeholder="someone continues a conversation off platform and resumes it in Quartz (circumvention)"
            value="someone continues a conversation off platform and resumes it in Quartz (circumvention)"
            startContent={
              <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
                <IconComponent iconNumber={2} color="#ffffff" />
              </div>
            }
          ></Input>
          <Input
            // label="Alert Me When:"
            placeholder="someone hasn’t engaged Quartz in over 48 hours (weekdays only)"
            value="someone hasn’t engaged Quartz in over 48 hours (weekdays only)"
            startContent={
              <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
                <IconComponent iconNumber={3} color="#ffffff" />
              </div>
            }
          ></Input>
          <Input
            // label="Alert Me When:"
            placeholder="someone is texting outside of standard working hours "
            value="someone is texting outside of standard working hours "
            startContent={
              <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
                <IconComponent iconNumber={4} color="#ffffff" />
              </div>
            }
          ></Input>

          <Button
            color="secondary"
            variant="ghost"
            className="w-fit self-end text-xs font-medium "
            size="sm"
          >
            Add Alert +
          </Button>
        </div>
      </div>
      <div className="relative col-span-3 flex h-full w-full flex-col gap-2">
        <Indicator
          isProcessed={false}
          type="status"
          label="4 New Alerts"
          containerClass="mt-4 mb-2"
        ></Indicator>
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="h-[1px] w-1 rounded-full bg-onyx-slate"></div>
          <p className="text-xs font-medium">Today</p>
          <div className="h-[1px] w-full rounded-full bg-onyx-slate"></div>
        </div>

        <AlertItem
          alert={{
            type: "Potential Compliance Violation",
            message: "Ben & Diego mentioned the word “earnings”",
            number_of_conversations: 2,
          }}
        />
        <AlertItem
          alert={{
            type: "Platform Subversion",
            message:
              "Christine & Shantanu had a conversation that appeared to continue off platform",
            number_of_conversations: 1,
          }}
        />
        <div className="absolute left-[14px] top-12 z-0 h-full w-[2px] bg-red-500/15"></div>
        <AlertItem
          alert={{
            type: "Platform Subversion",
            message: "Shantanu has not engaged with Quartz in 3 days",
          }}
        />
        <AlertItem
          alert={{
            type: "General Alert",
            message: "Ben & Shantanu were texting past regular working hours",
            number_of_conversations: 1,
          }}
        />
      </div>
    </div>
  )
}

export default Alerts

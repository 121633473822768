import {createClient} from "@supabase/supabase-js"

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error("Supabase URL or anon key is not set")
} else {
  console.log("Supabase URL and anon key are set")
}

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    // autoRefreshToken: false,
    persistSession: true,
  },
})

export default supabase

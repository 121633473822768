import React from "react"
import LoginContainer from "../components/auth/LoginContainer"
import {DotBackground} from "../components/general/ui/DotBackground"
import {BlurBallBackground} from "../components/general/ui/BlurBallBackground"
import {Sidebar, SidebarBody, SidebarLink} from "../components/general/Sidebar"
import {BackgroundCellCore} from "@/components/general/ui/GridAnimationBackground"

function Login() {
  return (
    <div className="relative flex h-dvh w-screen flex-col items-center justify-center overflow-hidden rounded-xl p-8 dark:bg-gray-900">
      <div className="absolute bottom-4 left-4 right-4 top-4 z-0 overflow-hidden rounded-xl bg-slate-900 ">
        <BackgroundCellCore />
      </div>
      <div className="z-10 h-fit w-fit">
        <LoginContainer />
      </div>

      {/* <div className="pointer-events-none absolute bottom-3 left-3 right-3 top-3 z-[-1] overflow-hidden rounded-xl">
        <DotBackground bg="black" dotColor="white" />
      </div> */}
    </div>
  )
}

export default Login

import supabase from "@/supabaseConfig"
import {graphql} from "@/gql"
import {SearchedThreadsDocument} from "@/gql/graphql"
import {useState, useEffect} from "react"
import {useQuery} from "@apollo/client"
export const searchMessages = async (
  searchTerm: string,
  page: number = 0,
  limit: number = 10
): Promise<{
  data: {thread_uid: string; message_id: string}[] | null
  error: Error | null
  hasMore: boolean
}> => {
  const offset = page * limit

  // Format search term: replace spaces with &, escape special chars
  const formattedSearch = searchTerm
    .trim()
    .split(/\s+/)
    .map((term) => term + ":*")
    .join(" & ")

  console.info("🔍 Search query:", formattedSearch)

  // Single query with count
  const {data, error, count} = await supabase
    .from("messages")
    .select(
      `
      message_id,
      thread_messages!inner(
        thread_uid
      )
    `,
      {count: "exact"} // Add count to the same query
    )
    .textSearch("search_vector", formattedSearch, {
      type: "websearch",
      config: "english",
    })
    .limit(limit)
    .range(offset, offset + limit - 1)

  const transformedData =
    data?.map((item) => ({
      message_id: item.message_id,
      thread_uid: item.thread_messages[0].thread_uid,
    })) || null

  const hasMore = count ? offset + limit < count : false

  console.debug("📊 Search results:", transformedData?.length ?? 0, "matches", {
    totalCount: count,
    hasMore,
  })

  return {data: transformedData, error, hasMore}
}

export function useSearchMessages(
  searchTerm: string,
  page: number,
  limit: number
) {
  const [data, setData] = useState<
    {thread_uid: string; message_id: string}[] | null
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    // Don't fetch if there's no search term
    if (!searchTerm) return

    const fetchData = async () => {
      setIsLoading(true)
      setError(null)

      try {
        console.info("🔄 Fetching search results for:", searchTerm)
        const result = await searchMessages(searchTerm, page, limit)
        if (result.error) {
          console.error("❌ Search error:", result.error)
        }
        setData(result.data)
      } catch (err) {
        setError(err instanceof Error ? err : new Error("An error occurred"))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [searchTerm, page, limit])

  return {
    data,
    isLoading,
    error,
  }
}

export function useSearchThreads(
  searchTerm: string,
  page: number = 0,
  limit: number = 10
) {
  const [data, setData] = useState<{
    threads: any[] // Replace 'any' with your thread type
    searchResults: {thread_uid: string; message_id: string}[]
  }>({threads: [], searchResults: []})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const {client} = useQuery(SearchedThreadsDocument)

  useEffect(() => {
    if (!searchTerm) {
      console.info("🔍 Search skipped: No search term provided")
      return
    }

    const fetchData = async () => {
      console.info("🔄 Starting search operation:", {searchTerm, page, limit})
      setIsLoading(true)
      setError(null)

      try {
        console.debug("📥 Fetching messages from search...")
        const searchResult = await searchMessages(searchTerm, page, limit)
        if (searchResult.error) throw searchResult.error

        const threadIds = searchResult.data?.map((msg) => msg.thread_uid) || []
        console.debug("🧵 Extracted thread IDs:", {
          count: threadIds.length,
          threadIds,
        })

        if (threadIds.length === 0) {
          console.info("ℹ️ No results found, returning empty data")
          setData({threads: [], searchResults: []})
          return
        }

        console.debug("📡 Fetching thread details from GraphQL...")
        const {data: threadsData} = await client.query({
          query: SearchedThreadsDocument,
          variables: {
            filter: {
              thread_uid: {in: threadIds},
            },
          },
        })

        const threads = threadsData?.threadsCollection?.edges ?? []

        // Create a map for quick thread lookup
        const threadMap = new Map(
          threads.map((thread) => [thread.node.thread_uid, thread])
        )

        // Sort threads according to the original search results order
        const orderedThreads = threadIds
          .map((id) => threadMap.get(id))
          .filter(Boolean)

        console.debug("📊 Search operation complete", {
          messagesFound: searchResult.data?.length ?? 0,
          threadsFound: orderedThreads.length,
        })

        setData({
          threads: orderedThreads,
          searchResults: searchResult.data ?? [],
        })
      } catch (err) {
        console.error("❌ Search operation failed:", err)
        setError(err instanceof Error ? err : new Error("An error occurred"))
      } finally {
        setIsLoading(false)
        console.debug("🏁 Search operation finished", {isError: !!error})
      }
    }

    fetchData()
  }, [searchTerm, page, limit, client])

  return {
    ...data,
    isLoading,
    error,
  }
}
